import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import Privacy from "./components/privacy/Privacy";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route } from "react-router-dom";

const Web = () => {
	return (
		<>
			<Router>
				<Route path="/" exact component={App} />
				<Route path="/privacy/" component={Privacy} />
			</Router>
		</>
	);
};

ReactDOM.render(<Web />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
