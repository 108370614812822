import React from "react";
import "./privacy.scss";

const Privacy = () => {
	return (
		<>
			<section className="privacytop">
				<h1>Privacy</h1>
			</section>
			<div className="wrapper-privacy">
				<table>
					<tbody>
						<tr>
							<td colspan="2" width="654">
								<strong>Informazioni sul trattamento dei dati personali</strong>
							</td>
						</tr>
						<tr>
							<td width="170">Titolare</td>
							<td width="484"> Distribuzione Italia s.r.l.</td>
						</tr>
						<tr>
							<td width="170">Finalità del trattamento</td>
							<td width="484">
								·         Dare riscontro a richieste dell’interessato, Gestire
								la partecipazione dell’interessato alle attività svolte dal
								Titolare, Dare esecuzione ad obblighi di legge. ·        
								Utilizzo dei dati a fini informativi  attraverso l’invio di
								comunicazioni per promuovere l’adesione alle iniziative del
								Titolare e per far conoscere e sostenere la sua attività.
								·         Comunicare i dati a terzi che li useranno per futuri
								contatti promozionali
							</td>
						</tr>
						<tr>
							<td width="170">
								Basi giuridiche che legittimano il trattamento
							</td>
							<td width="484">
								·        {" "}
								<strong>
									Dare esecuzione alla richiesta dell’interessato o eseguire
									obbblighi contrattuali precontrattuali o di legge
								</strong>
								. Questa base giuridica legittima il trattamento dei dati per
								dare riscontro a richieste dell’interessato ·        {" "}
								<strong>Consenso dell’interessato. </strong>Qiuesta base
								giuridica legittima il trattamento dei dati per utilizzare i
								dati per l’invio di comunicazioni commerciali e per comunicare i
								dati a terzi
							</td>
						</tr>
						<tr>
							<td width="170">Destinatari</td>
							<td width="484">
								·         Fornitori di servizi strumentali alle finalità
								dettagliatamente indicate nel punto 2 delle Informazioni sul
								trattamento dei dati. ·         Società, organizzazioni e/o enti
								che ricevono i dati per effetto del consenso espresso
								dall’interessato. Tali destinatari di dati appartengono alle
								categorie mercelogiche de
							</td>
						</tr>
						<tr>
							<td width="170">Diritti</td>
							<td width="484">
								L’interessato avrà diritto, in relazione ai propri dati, a:
								<ul>
									<li>Accedere,</li>
									<li>Cancellare o rettificare,</li>
									<li>Limitare il proprio trattamento,</li>
									<li>Richiedere la portabilità,</li>
									<li>Opporsi al trattamento.</li>
								</ul>
								In ogni caso l’interessato può presentare ricorso al Garante per
								la protezione dei dati personali
							</td>
						</tr>
						<tr>
							<td width="170">Informazioni Aggiuntive</td>
							<td width="484">
								Può consultare le Informazioni Aggiuntive sulla protezione dei
								propri dati personali accendo al documento consultabile qui di
								seguito
							</td>
						</tr>
					</tbody>
				</table>
				<div className="text-wrapper">
					<p>
						In accordo con quanto indicato nel Regolamento UE 2016/679 ( GDPR)
						forniamo  le seguenti informazioni sul trattamento dei dati:
					</p>
					<ol>
						<li>
							<strong> SOGGETTO TITOLARE DEL TRATTAMENTO DEI DATI</strong>
						</li>
					</ol>
					Distribuzione Italia s.r.l. Sede Via Sciadonna 00044 Frascati RM C.F.
					e P.I. 07548871008 Telefono: 0694282764 Dati di contatto:
					privacy@distribuzioneitalia.it  da utilizzare anche per il contatto
					con il responsabile della protezione dei dati
					<ol start="2">
						<li>
							<strong>
								{" "}
								FINALITÀ DEL TRATTAMENTO E TEMPO DI CONSERVAZIONE DEI DATI
							</strong>
						</li>
					</ol>
					<strong>FINALITÀ 1</strong>: dare riscontro alle richieste degli
					interessati, dare esecuzione ad obblighi contrattuali e
					precontrattuali ed obblighi di legge.
					<strong>FINALITÀ 2:</strong> Utilizzo dei dati a fini promozionali
					attraverso l’invio di comunicazioni commerciali di terzi o
					realizzazione di studi di mercato. A tal fine potrebbe essere
					necessario utilizzare i dati degli interessati per operazioni di
					normalizzazione, deduplica, filtraggio e verifica. Le comunicazioni
					verranno inviate dal TITOLARE.
					<strong>FINALITA 3</strong>:  Comunicare i dati a terzi per l’invio di
					comunicazioni commerciali Le azioni promozionali realizzate dal
					TITOLARE potranno effetuarsi attraverso i seguenti canali:
					<ul>
						<li>posta elettronica,</li>
						<li>posta,</li>
						<li>chiamate telefoniche a linee fisse o cellulari,</li>
						<li>invio di messaggi SMS e/o MMS,</li>
						<li>serivizi di piattaforma di messaggistica, chat</li>
						<li>qualsiasi altro tipo di comunicazione analoga.</li>
					</ul>
					<strong>
						<u>Conservazione dei dati personali:</u>
					</strong>
					Con riferimento ai dati raccolti dal Titolare per richieste
					dell’interessato, esecuzione di obblighi contrattuali, precontrattuali
					o di legge: Il Titolare conserverà la banca dati per il tempo
					necessario alla gestione delle attività connesse al trattamento dei
					dati. Con riferimento ai dati raccolti dal Titolare finalizzato a
					comunicazioni commerciali di terzi o realizzazione di studi di
					mercato: il Titolare conserverà la banca dati per un periodo non
					superiore ai 18 mesi. Con riferimento ai dati raccolti dal Titolare
					finalizzato a comunicare i dati a terzi per l’invio di comunicazioni
					commerciali: il Titolare conserverà la banca dati per un periodo non
					superiore ai 18 mesi.
					<ol start="3">
						<li>
							<strong>
								{" "}
								BASI GIURIDICHE CHE LEGITTIMANO IL TRATTAMENTO DEI DATI
							</strong>
						</li>
					</ol>
					La legittimazione per il trattamento dei suoi dati in relazione alle
					finalità del trattamento si basa sul seguente:
					<ul>
						<li>
							Al fine della gestione delle richieste dell’interessato e la
							partecipazione dell’interessato ad attività svolte dal Titolare -
							Necessità di dare esecuzione a richieste dell’interessato.
						</li>
					</ul>
					<ul>
						<li>
							Al fine della realizzazione di comunicazioni da parte del Titolare
							– Consenso dell’interessata
						</li>
						<li>
							Al fine di comunicare i dati a terzi – Consenso dell’interessato
						</li>
					</ul>
					<ol start="4">
						<li>
							<strong>
								{" "}
								DESTINATARI A CUI VERRANNO COMUNICATI I DATI PERSONALI
							</strong>
						</li>
					</ol>
					<strong>
						<em> </em>
					</strong>
					<strong>
						<em> </em>
					</strong>
					I dati raccolti per richieste dell’interessato rispetto a richieste
					organizzate dal Titolare potranno essere comunicati a soggetti
					autorizzati al trattamento e responsabili fornitori necessari per il
					trattamento dei dati personali. Dati raccolti per la realizzazione di
					azioni di informazione da parte del Titolare non sono previste
					comunicazioni di dati a destinatari terzi ma i dati potranno essere
					trattati da responsabili preposti al trattamento con specifico atto
					giuridico. I dati raccolti in  caso di consenso dell’interessato
					verranno comunicati a società terze, organizzazioni o enti interessati
					ad indirizzare comunicazioni commerciali agli interessati. Tali
					soggetti soggetti saranno:
					<ol>
						<li>
							Società pubblicitarie appartenenti ai settori di attività indicati
							di seguito al presente punto 4;
						</li>
						<li>Società di Marketing;</li>
						<li>Call Center.</li>
						<li>
							Sponsor della richiesta di informazioni cui fa riferimento il form
							di registrazione.
						</li>
					</ol>
					I settori di attività per cui gli interessati potrebbero ricevere
					informazioni sono i seguenti:
					<ul>
						<li>
							Comunicazioni: prodotti e servizi di comunicazioni e tecnologia,
							etc.;
						</li>
						<li>
							Finanza e settore bancario: entità finanziarie, assicurazioni,
							investimento, previdenza sociale, etc.;
						</li>
						<li>
							Tempo Libero: pay tv, editoriali, sport, collezionismo,
							fotografia, passatempo, giochi, trasporti, navigazione, turismo
							(hotel, tour operator, agenzie di viaggi, compagnie aeree, etc.),
							giardinaggio, hobbies, lotteria, concorsi, comunicazione e
							entertainment, arte, musica, etc.;
						</li>
						<li>
							• Energia e acqua: prodotti relazionati con elettricità,
							idrocarburi, gas, acqua e utilità, etc.;
						</li>
					</ul>
					<ol start="5">
						<li>
							<strong>
								{" "}
								DIRITTI CHE LE APPARTENGONO QUANDO CI INDICA I PROPRI DATI
								PERSONALI
							</strong>
						</li>
					</ol>
					<strong> </strong>
					Lei ha diritto a:
					<ul>
						<li>Accedere ai suoi dati.</li>
						<li>Chiedere la rettificazione</li>
						<li>Chiedere la cancellazione</li>
						<li>Opporsi al trattamento.</li>
						<li>Chiedere la limitazione del trattamento.</li>
						<li>Chiedere la portabilità dei dati.</li>
					</ul>
					Potrà esercitare i propri diritti direttamente e gratuitamente
					contattando il seguente indirizzo email{" "}
					<a href="mailto:privacy@distribuzioneitalia.it">
						privacy@distribuzioneitalia.it
					</a>{" "}
					e indicando nell’oggetto “ESERCIZIO DEL DIRITTO IN MATERIA DI DATI
					PERSONALI” specificando quale diritto richieda o per comunicazione
					scritta all’indirizzo del Titolare. La sua richiesta dovrà indicare
					date, nome, cognome, richiesta, indirizzo a fini di notifica. Per
					evitare qualsiasi fraintendimento sull’identità della persona fisica
					che richiede l’esercizio dei propri diritti il Titolare si riserva di
					chiedere copia del documento di identità per confermare l’identità del
					richiedente nei casi in cui ciò sia necessario. E’ diritto
					dell’interessato proporre reclamo al  al Garante per la protezione dei
					dati personaliall'indirizzo{" "}
					<a href="http://www.garanteprivacy.it/">www.garanteprivacy.it</a>
				</div>
			</div>
		</>
	);
};

export default Privacy;
