import React, { useEffect } from "react";
import Data from "../Data";

const SideTabs = props => {
	const Tabs = props => {
		useEffect(() => {
			let HTML = document.getElementsByClassName("aside-item");
			let htmlarr = Array.from(HTML);

			htmlarr[0].classList.add("active");

			htmlarr.map((e, index) => {
				if (index === props.state && e.classList.contains("active") === false) {
					e.classList.toggle("active");
				}
				if (index !== props.state && e.classList.contains("active")) {
					e.classList.toggle("active");
				}
			});
		}, [props.state]);

		let arr;
		arr = Object.values(Data.OffertaFisso);
		if (!props.upperstate) {
			arr = Object.values(Data.OffertaMobile);
		}
		let colHTML = document.getElementsByClassName("aside-item");
		const check = "titoloOfferta";
		const result = arr.map((e, index) => {
			if (e.hasOwnProperty(check)) {
				return (
					<div
						className="aside-item"
						onClick={event => {
							console.log(index);
							let key = index;
							props.tabClick({ key, colHTML });
						}}
					>
						<p>{e.titoloOfferta}</p>
					</div>
				);
			}
		});

		return result;
	};

	return (
		<>
			<aside className="aside">
				<Tabs
					tabClick={props.tabClick}
					upperstate={props.upperstate}
					state={props.state}
				/>
			</aside>
		</>
	);
};

export default SideTabs;
