import React from "react";

const Hero = () => {
	return (
		<section className="hero-img">
			<h1>Scopri le nuove offerte Business</h1>
		</section>
	);
};

export default Hero;
