import React, { useState } from "react";
import "./App.scss";
import Tab from "./components/Tab";
import timLogo from "./static/timalfabianco.png";
import distribzuioneLogo from "./static/distribuzione-italia.png";
import Hero from "./components/hero";
import Modal from "./components/modal";
import { Transition } from "react-spring/renderprops";

const Nav = () => {
	return (
		<div className="top_bar">
			<img src={timLogo} alt="Tim Business" id="tim" />
			<img
				src={distribzuioneLogo}
				alt="Distribzuione Italia"
				id="distribuzione"
			/>
		</div>
	);
};

function App() {
	const [modal, setModal] = useState(false);

	let showModal = props => {
		setModal(!modal);
		return;
	};
	return (
		<div className="App">
			<Transition
				items={modal}
				from={{ opacity: 0, zIndex: 9 }}
				enter={{ opacity: 1 }}
				leave={{ opacity: 0 }}
			>
				{modal =>
					modal &&
					(props => (
						<div style={props}>
							<Modal show={modal} changeShow={setModal} />
						</div>
					))
				}
			</Transition>
			<Nav />
			<Hero />
			<Tab show={modal} changeShow={setModal} showModal={showModal} />
		</div>
	);
}

export default App;
