import React from "react";

const UpperTab = props => {
	return (
		<>
			<div
				className="tab"
				id="fisso"
				onClick={event => {
					const key = 0;
					const data = {
						key
					};

					props.activeClick(data);
					props.setData();
				}}
			>
				<p>Fisso</p>
			</div>
			<div
				className="tab"
				id="mobile"
				onClick={event => {
					const key = 1;
					const data = {
						key
					};

					props.activeClick(data);
					props.setData();
				}}
			>
				<p>Mobile</p>
			</div>
		</>
	);
};
export default UpperTab;
