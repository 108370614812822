const Data = {
	OffertaFisso: {
		0: {
			costobis: { testo: '' },
			titoloOfferta: 'TIM SENZA LIMITI FIBRA',
			fissoLeft: 'Fisso',
			fissoRight: '1 canale voce VOIP',
			internetLeft: 'Internet',
			internetRight:
				'Internet con navigazione illimitata, in tecnologia Fibra FTTH di Tim (fino a 1 Giga in download).  Verifica prima la copertura Fibra con il nostro consulente',
			voceLeft: 'Voce',
			voceRight: 'Chiamate Nazionali ILLIMITATE verso Rete Fissa e Mobile',
			routerLeft: 'Router TIM',
			routerRight: `In vendita a +6,10€ per 48 rate.
            In caso di recesso anticipato, pagamento delle rate residue
            come richiesto al momento della sottoscrizione`,
			costo: {
				prezzo: '25€',
				testo: 'per i primi 12 mesi, poi 35€ dal 13° mese'
			},
			costoIniziale: {
				titolo: 'CONTRIBUTO ATTIVAZIONE',
				sottotitoloNuovi: 'PER NUOVE ATTIVAZIONI',
				costoNuovi: '300€(rateizzare a 12,50€/mese per 24 mesi)',
				sottotitoloPassaggi: 'PER PASSAGGI A TIM',
				costoPassaggio: '120€(rateizzata a 5€/mese per 24 mesi)'
			}
		},

		1: {
			titoloOfferta: 'TIM SENZA LIMITI ADSL',
			fissoLeft: 'Fisso',
			fissoRight: `1 canale voce (RTG) O
            2 canali voce (1 ISDN)`,
			internetLeft: 'Internet',
			internetRight: `Internet con tecnologia ADSL2+ fino a 20 Mega in Download
            e 1 Mega in Upload. Verifica prima la copertura ADSL.`,
			voceLeft: 'Voce',
			voceRight: 'Chiamate Nazionali ILLIMITATE verso Rete Fissa e Mobile',
			routerLeft: 'Router TIM',
			routerRight: `In vendita a +6,10€ per 48 rate.
            In caso di recesso anticipato, pagamento delle rate residue
            come previsto al momento della sottoscrizione`,
			costo: {
				prezzo: '25€ / 60€',
				testo: `1 CANALE VOCE : per i primi 12 mesi, poi 35€ dal 13° mese`
			},
			costobis: {
				prezzo: '60€',
				testo:
					'2 CANALI VOCE ( 1 ISDN) : per i primi 12 mesi, poi 70€ dal 13° mese'
			},
			costoIniziale: {
				titolo: 'CONTRIBUTO ATTIVAZIONE',
				sottotitoloNuovi: 'PER NUOVE ATTIVAZIONI',
				costoNuovi: '300€(rateizzare a 12,50€/mese per 24 mesi)',
				sottotitoloPassaggi: 'PER PASSAGGI A TIM',
				costoPassaggio: '120€(rateizzata a 5€/mese per 24 mesi)'
			}
		},

		2: {
			costobis: { testo: '' },
			titoloOfferta: 'TIM START XDSL',
			fissoLeft: 'Fisso',
			fissoRight: '1 canale voce VOIP',
			internetLeft: 'Internet',
			internetRight: `Internet in tecnologia FTTCab
                e FTTE
                fino a 200Mbps / 100Mbps.
               Verifica prima la copertura Fibra con il nostro consulente.`,
			voceLeft: 'Voce',
			voceRight: `Chiamate verso tutti i numeri fissi e mobili nazionali
            con scatto alla risposta di 0,35€`,
			routerLeft: 'Router TIM',
			routerRight: `In vendita a +6,10€ per 48 rate.
            In caso di recesso anticipato, pagamento delle rate residue
            come richiesto al momento della sottoscrizione
            `,
			costo: {
				prezzo: '15€',
				testo: 'per i primi 12 mesi, poi 25€ dal 13° mese'
			},
			costoIniziale: {
				titolo: 'CONTRIBUTO ATTIVAZIONE',
				sottotitoloNuovi: 'PER NUOVE ATTIVAZIONI',
				costoNuovi: '300€(rateizzare a 12,50€/mese per 24 mesi)',
				sottotitoloPassaggi: 'PER PASSAGGI A TIM',
				costoPassaggio: '120€(rateizzata a 5€/mese per 24 mesi)'
			}
		},

		3: {
			costobis: { testo: '' },
			titoloOfferta: 'TIM SENZA LIMITI XDSL',
			fissoLeft: 'Fisso',
			fissoRight: '1 canale voce VOIP',
			internetLeft: 'Internet',
			internetRight: `Internet in tecnologia FTTCab
			e FTTE
			fino a 200Mbps / 100Mbps.
		 Verifica prima la copertura Fibra con il nostro consulente.`,
			voceLeft: 'Voce',
			voceRight: `Chiamate Nazionali ILLIMITATE verso Rete Fissa e Mobile`,
			routerLeft: 'Router TIM',
			routerRight: `In vendita a +6,10€ per 48 rate.
            In caso di recesso anticipato, pagamento delle rate residue
            come richiesto al momento della sottoscrizione
            `,
			costo: {
				prezzo: '25€',
				testo: 'per i primi 12 mesi, poi 35€ dal 13° mese'
			},
			costoIniziale: {
				titolo: 'CONTRIBUTO ATTIVAZIONE',
				sottotitoloNuovi: 'PER NUOVE ATTIVAZIONI',
				costoNuovi: '300€(rateizzare a 12,50€/mese per 24 mesi)',
				sottotitoloPassaggi: 'PER PASSAGGI A TIM',
				costoPassaggio: '120€(rateizzata a 5€/mese per 24 mesi)'
			}
		}
	},
	OffertaMobile: {
		// Small: {
		// 	titoloOfferta: 'Small',
		// 	key: 0,
		// 	prezzo: '15€',
		// 	rete: '4G',
		// 	giga: '5GB',
		// 	minsms: 'minuti illimitati e 50 sms',
		// 	extraNal: 'A partire da offerta Large'
		// },

		Large: {
			titoloOfferta: 'Large',
			key: 1,
			prezzo: '15€',
			rete: '4G',
			giga: '20GB',
			minsms: 'minuti illimitati e 100 sms',
			extraNal: `50 Minuti e 50 sms da Italia vs EU`
		},

		// XLarge: {
		// 	titoloOfferta: 'Extra Large',
		// 	key: 2,
		// 	prezzo: '30€',
		// 	rete: '4G',
		// 	giga: '30GB',
		// 	minsms: 'minuti e sms  illimitati',
		// 	extraNal: `100 Minuti e 100 sms da Italia vs EU`
		// },

		// SLimiti: {
		// 	titoloOfferta: 'Tim Senza Limiti 5G',
		// 	key: 3,
		// 	prezzo: '60€',
		// 	rete: '5G',
		// 	giga: '100GB +5Gb in 16 paesi',
		// 	minsms: 'minuti e sms  illimitati',
		// 	extraNal: `minuti ed sms illimitati da e verso EU TOP 16`
		// },

		servizi: {
			mail: `Uso mail senza consumo Giga. Inclusi tutti i principali protocolli mail raggiungibili anche da APP.`,
			social: `Navighi senza consumare Giga sui Social: Facebook, Instagram, Linkedin (ed altri)`,
			chat: `Navighi senza consumare Giga sulle principali Chat:  Whatsapp, Facebook, Skype, Telegram (ed altri).
            `
		}
	}
};

export default Data;
