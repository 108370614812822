import React from 'react';
import SideTabs from './sidetabs';
import Data from '../Data';
import { useSpring, animated } from 'react-spring';

const MainSection = props => {
	const anim = useSpring({ opacity: 1, from: { opacity: 0 } });
	const Offers = props => {
		if (props.upperstate) {
			const keys = Object.values(Data.OffertaFisso);
			let {
				fissoLeft,
				fissoRight,
				internetLeft,
				internetRight,
				voceLeft,
				voceRight,
				routerLeft,
				routerRight,
				costo,
				costobis,
				costoIniziale
			} = keys[props.state];

			return (
				<>
					<div className='leftui' id='fisso2'>
						<p>{fissoLeft}</p>
					</div>
					<div className='leftui' id='internet'>
						<p>{internetLeft}</p>
					</div>
					<div className='leftui' id='voce'>
						<p>{voceLeft}</p>
					</div>
					<div className='leftui' id='router'>
						<p>{routerLeft}</p>
					</div>
					<div className='rightui' id='fissoinfo'>
						<p>{fissoRight}</p>
					</div>
					<div className='rightui f' id='internetinfo'>
						<p>{internetRight}</p>
					</div>
					<div className='rightui' id='voceinfo'>
						<p>{voceRight}</p>
					</div>
					<div className='rightui f' id='routerinfo'>
						<p>{routerRight}</p>
					</div>
					<div id='costo'>
						<div className='wrapper'>
							<h3>Costo Mensile</h3>
							<h1 className='wrapper-items'>{costo.prezzo}</h1>
							<p className='wrapper-items'>{costo.testo}</p>
							<p className='wrapper-items'>{costobis.testo}</p>
						</div>
					</div>
					<div id='initcost'>
						<div className='wrapper'>
							<h3 className='wrapper-items'>{costoIniziale.titolo}</h3>
							{/* <h4 className='wrapper-items'>
								{costoIniziale.sottotitoloNuovi}
							</h4>
							<p className='wrapper-items'>{costoIniziale.costoNuovi}</p> */}
							<h4 className='wrapper-items'>
								{costoIniziale.sottotitoloPassaggi}
							</h4>
							<p className='wrapper-items'>{costoIniziale.costoPassaggio}</p>
						</div>
					</div>
				</>
			);
		} else {
			const keys = Object.values(Data.OffertaMobile);
			let { prezzo, rete, giga, minsms, extraNal } = keys[props.state];

			return (
				<>
					<div className='leftui' id='fisso2'>
						<p>Rete</p>
					</div>
					<div className='leftui' id='internet'>
						<p>Internet</p>
					</div>
					<div className='leftui' id='voce'>
						<p>Minuti & SMS</p>
					</div>
					<div className='leftui' id='router'>
						<p>Estero</p>
					</div>
					<div className='rightui' id='fissoinfo'>
						<p>{rete}</p>
					</div>
					<div className='rightui' id='internetinfo'>
						<p>{giga}</p>
					</div>
					<div className='rightui' id='voceinfo'>
						<p>{minsms}</p>
					</div>
					<div className='rightui' id='routerinfo'>
						<p>{extraNal}</p>
					</div>
					<div id='costo'>
						<div className='wrapper'>
							<h3>Costo Mensile</h3>
							<h1 className='wrapper-items'>{prezzo}</h1>
							<p class='wrapper-items'>
								per i primi 12 mesi, poi 20€ dal 13° mese
							</p>
						</div>
					</div>
					<div id='initcost'>
						<div className='wrapper'>
							<p className='wrapper-items' id='mservice'>
								{Data.OffertaMobile.servizi.mail}
							</p>
							<p className='wrapper-items' id='mservice'>
								{Data.OffertaMobile.servizi.social}
							</p>
							<p className='wrapper-items' id='mservice'>
								{Data.OffertaMobile.servizi.chat}
							</p>
						</div>
					</div>
				</>
			);
		}
	};

	const AnimateOffers = animated(Offers);

	return (
		<>
			<SideTabs
				tabClick={props.tabClick}
				upperstate={props.upperstate}
				state={props.state}
				switcher={props.switcher}
			/>

			<section className='main'>
				<AnimateOffers
					style={anim}
					state={props.state}
					onChange={Offers}
					upperstate={props.upperstate}
				/>

				<div id='buttons'>
					<div
						id='okButton'
						onClick={e => {
							props.showModal();
						}}
					>
						<p>Scopri di più</p>
					</div>
				</div>
			</section>
		</>
	);
};

export default MainSection;
