/* eslint-disable no-const-assign */
import React, { useState, useEffect } from "react";
import UpperTab from "./tab-components/uppertab";

import MainSection from "./tab-components/mainsection";
import "./offertable.scss";
import Data from "./Data";

function Tab(props) {
	const [fissoActive, setFissoActive] = useState(true);
	const [mobileActive, setMobileActive] = useState(false);
	const [tabActive, setTabActive] = useState(0);

	const [cache, setCache] = useState(Data.OffertaFisso);

	useEffect(() => {
		let arr = document.getElementsByClassName("tab");
		let display = Array.from(arr);
		display[0].classList.add("active");
	}, []);

	const activeClick = props => {
		const arr = document.getElementsByClassName("tab");
		const display = Array.from(arr);
		let fissoIsSet = fissoActive;
		let mobileIsSet = mobileActive;

		if (props.key === 0 && !fissoIsSet) {
			fissoIsSet = true;
			mobileIsSet = false;
			display[0].classList.toggle("active");
			display[1].classList.toggle("active");
		} else if (props.key === 1 && !mobileIsSet) {
			display[0].classList.toggle("active");
			display[1].classList.toggle("active");
			fissoIsSet = false;
			mobileIsSet = true;
		}

		setFissoActive(fissoIsSet);
		setMobileActive(mobileIsSet);
	};

	const tabClick = props => {
		let display = Array.from(props.colHTML);
		let whichTab;
		display.map((e, index) => {
			if (index === props.key) {
				whichTab = index;
				return whichTab;
			}
		});
		setTabActive(whichTab);
	};

	const setData = () => {
		let tempData = Data;
		let fissoIsSet = fissoActive;
		let mobileIsSet = mobileActive;

		if (fissoIsSet) {
			tempData = Data.OffertaFisso;
		} else if (mobileIsSet) {
			tempData = Data.OffertaMobile;

			setCache(tempData);
		}
	};

	return (
		<div className="offer_table">
			<UpperTab
				activeClick={activeClick}
				setData={setData}
				upperstate={fissoActive}
			/>

			<MainSection
				tabClick={tabClick}
				state={fissoActive ? tabActive : 0}
				upperstate={fissoActive}
				optionState={props.optionState}
				show={props.show}
				changeShow={props.changeShow}
				showModal={props.showModal}
			/>
		</div>
	);
}

export default Tab;
