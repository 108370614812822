import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Modal = props => {
	useEffect(() => {
		let HTMLthankyou = document.getElementsByClassName("thankyou");
		HTMLthankyou[0].style.display = "none";
		window.fbq("track", "ViewContent");
	}, []);
	const [values, setValues] = useState({
		nome: null,
		ragioneSociale: null,
		codiceFiscale: null,
		telefono: null,
		migrazione: null,
		offertaFisso: null,
		offertaMobile: null
	});
	let options = null;

	const handleInputChange = e => {
		const { name, value } = e.target;
		setValues({ ...values, [name]: value });
	};
	const handleSelect = e => {
		if (e.target.classList.contains("fisso")) {
			if (e.target.value === "null") {
				return setValues({ ...values, offertaFisso: null });
			}
			setValues({ ...values, offertaFisso: e.target.value });
		} else if (e.target.classList.contains("mobile")) {
			if (e.target.value === "null") {
				return setValues({ ...values, offertaMobile: null });
			}
			setValues({ ...values, offertaMobile: e.target.value });
		}
	};

	const ThankYou = () => {
		let HTMLform = document.getElementsByTagName("form");
		let HTMLthankyou = document.getElementsByClassName("thankyou");
		let HTMLmodal = document.getElementsByClassName("modal");
		HTMLform[0].style.display = "none";
		HTMLthankyou[0].style.display = "flex";
		HTMLmodal[0].style.height = "300px";
		HTMLmodal[0].style.top = "25%";
	};

	if (!props.show) {
		return <></>;
	} else {
		return (
			<>
				<div className="modal">
					<button
						onClick={() => {
							props.changeShow(false);
						}}
					>
						X
					</button>
					<div className="thankyou">
						<h1>
							Grazie per aver inviato i dati. Verrà ricontattato il prima
							possibile da un nostro operatore
						</h1>
					</div>

					<form
						onSubmit={e => {
							e.preventDefault();
							if (values.fisso === null && values.mobile === null) {
								return alert("Prego scegliere almeno una offerta");
							}
							options = {
								method: "POST",
								body: JSON.stringify(values),
								headers: {
									"Content-Type": "application/json"
								}
							};

							fetch("https://server-lead.makeyoudigit.com/leads/30", options)
								.then(ThankYou())
								.then(window.fbq("track", "Lead"));
						}}
					>
						<div className="form-wrap">
							<label className="labels">Nome e Cognome</label>
							<input
								type="text"
								className="inputs"
								value={values.nome}
								name="nome"
								onChange={e => {
									handleInputChange(e);
								}}
								required
							></input>
						</div>
						<div className="form-wrap">
							<label className="labels">Ragione Sociale</label>
							<input
								type="text"
								className="inputs"
								value={values.ragioneSociale}
								name="ragioneSociale"
								onChange={e => {
									handleInputChange(e);
								}}
								required
							></input>
						</div>
						<div className="form-wrap">
							<label className="labels">Cod. Fiscale o P.IVA</label>
							<input
								type="text"
								className="inputs"
								value={values.codiceFiscale}
								name="codiceFiscale"
								onChange={e => {
									handleInputChange(e);
								}}
								required
							></input>
						</div>
						<div className="form-wrap">
							<label className="labels">Cellulare</label>
							<input
								type="text"
								className="inputs"
								value={values.telefono}
								name="telefono"
								onChange={e => {
									handleInputChange(e);
								}}
								required
							></input>
						</div>
						<div className="form-wrap">
							<label className="labels">Numero da migrare</label>
							<input
								type="text"
								className="inputs"
								name="migrazione"
								value={values.migrazione}
								onChange={e => {
									handleInputChange(e);
								}}
								required
							></input>
						</div>
						<div className="form-wrap" id="offers">
							<label className="labels">Scegliere Offerte di Interesse</label>

							<select
								className="selects fisso"
								onChange={e => {
									handleSelect(e);
								}}
							>
								<option value="null">Scegli Offerta Fisso</option>
								<option value="Tim Senza Limiti Fibra">
									Tim Senza Limiti Fibra
								</option>
								<option value="Tim Senza Limiti ADSL">
									Tim Senza Limiti ADSL
								</option>
								<option value="Tim Start XDSL">Tim Start XDSL</option>
								<option value="Tim Senza Limiti XDSL">
									Tim Senza Limiti XDSL
								</option>
							</select>
							<select
								className="selects mobile"
								onChange={e => {
									handleSelect(e);
								}}
							>
								<option value="null">Scegli Offerta Mobile</option>		
								<option value="Large">Large</option>
							</select>
						</div>
						<input type="submit" value="Inserisci Dati" id="submitlead" />
						<div id="link">
							<p>
								Inserendo il numero di telefono e cliccando sul tasto Inserisci
								Dati, autorizzo Distrubuzione Italia s.r.l. a contattarmi per
								attività di marketing telefonico. Cliccando su "Inserisci"
								dichiaro di aver preso visione dell'
							</p>
						</div>
					</form>
					<Link to="/privacy">informativa privacy</Link>
				</div>
				<div
					className="bg-modal"
					onClick={event => {
						props.changeShow(false);
					}}
				></div>
			</>
		);
	}
};

export default Modal;
